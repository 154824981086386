
#tooltip:empty {
  display: none;
}
#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  position: absolute;
  padding: 4px;
  margin: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 10px;
  z-index: 9;
  pointer-events: none;
}

.control-panel {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  margin: 12px;
  padding: 20px;
  z-index: 1;
}

label {
  display: inline-block;
  width: 150px;
}